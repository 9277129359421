<template>
  <div>
    <Row :gutter="50">
      <i-col span="24">
        <Form
          ref="form"
          :model="form"
          :rules="rules"
          style="padding-left:10px;"
          class="register-form"
        >
          <FormItem label="Current Password" prop="old_password">
            <Input type="password" v-model="form.old_password" />
          </FormItem>

          <FormItem label="New Password" prop="new_password">
            <Input type="password" v-model="form.new_password" />
          </FormItem>

          <FormItem label="Re-type New Password" prop="re_password">
            <Input type="password" v-model="form.re_password" />
          </FormItem>

          <div class="update-info" :loading="editLoading" @click="editSubmit">
            Update Password
          </div>
        </Form>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import api from "@/api";
const { _resetPassword } = api;

export default {
  computed: mapState("user", ["avatar"]),

  data() {
    return {
      editLoading: false,
      form: {
        old_password: "",
        new_password: "",
        re_password: ""
      },
      rules: {
        old_password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          }
        ],
        new_password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          }
        ],
        re_password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          },
          {
            message: "The confirmation password is not identical",
            validator: (rule, value) => {
              return value === this.form.new_password;
            }
          }
        ]
      }
    };
  },
  methods: {
    editSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.editLoading = true;
          _resetPassword({
            new_password: this.form.new_password,
            old_password: this.form.old_password
          })
            .then(() => {
              this.editLoading = false;
              this.$Message.success("success");
            })
            .catch(({ message }) => {
              this.editLoading = false;
              this.$Message.error(message);
            });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.update-info {
  width: 220px;
  height: 38px;
  background: #3E1DCE;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;

  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  text-align: center;
  /*padding-top: 9px;*/
  /*margin-left: 40px;*/
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}
.register-form >>> .ivu-input {
  height: 38px;
  width: 300px;
  border-radius: 0.75rem;
  border: 2px solid #dfdfe6;
  outline: none;
  padding-left: 10px
}
.register-form >>> .ivu-select-input {
  height: 36px;
  width: 300px;
}
.register-form >>> .ivu-form-item {
  padding-left: 10px;
  margin-bottom: 7px;
}
.register-form >>> .ivu-form-item-label {
  font-size: 18px;

  font-weight: 500;
  color: #292929;
  line-height: 18px;
}
.register-form >>> .input-note textarea {
  height: 100px;
}
</style>
